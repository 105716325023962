.ponents {
    background-image: url("img/foove-fons.svg");
    background-repeat: no-repeat;
    background-position: 100% -25px;
    &__item {
        @include desktop {
            &:nth-child(odd) {
                flex-direction: row-reverse;
            }
        }
        h3 {
            max-width: min-content;
        }
        img {
            object-fit: cover;
            @include desktop {
                height: 100%;
            }
        }
    }
}
