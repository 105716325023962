footer {
    p {
        letter-spacing: 2.92px;
    }
    ul:last-of-type {
        li + li {
            border-left: 1px solid #fff;
            padding-left: var(--space-s);
        }
    }
}
