.main-nav {
    letter-spacing: 3.79px;
    border: solid $secondary;
    border-width: 1px 0;
    column-gap: var(--space-2xl);
    row-gap: var(--space-m);
    @include mobile {
        margin-top: var(--space-2xl);
    }
}
