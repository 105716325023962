.full-link {
    position: relative;
    img {
        transition: opacity 0.125s ease-in;
    }
    &:hover {
        img {
            opacity: 0.85;
        }
    }
    a::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}
