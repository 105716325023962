.play-button-container {
    position: relative;
    display: block;
    cursor: pointer;
}

.play-button-container img {
    display: block;
    width: 100%;
    height: auto;
}

.play-button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fons negre amb opacitat */
    display: flex;
    align-items: center;
    justify-content: center;
}


.video-container {
    position: relative;
    padding-bottom: 56.25%; /*16:9*/
    // padding-top: 30px;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.popup-content {
    max-width: 80%;
    margin: 0 auto;
    background: #000; /* Fons negre */
    padding: 20px; /* Espai al voltant del contingut */
    box-sizing: border-box;
}

.mfp-bg{
    background: #000;
}

.mfp-close-btn-in .mfp-close {
    color: #F0D300;
}
