.is-bordered {
    &:after {
        content: "";
        display: block;
        height: 1px;
        width: 6.25rem;
        background-color: $primary;
        margin-top: var(--space-s);
    }
    &--right{
        &:after {
            content: "";
            display: block;
            height: 100%;
            width: 1px;
            background-color: $white;
            top: 0;
            right: 0;
            position: absolute;
        }
    }
    &--bottom{
        &:after {
            content: "";
            display: block;
            height: 31px;
            width:  100%;
            background-color: $secondary;
            bottom: 0;
            position: absolute;
        }
    }
}
h2.is-bordered {
    &:after {
        height: 6px;
        width: 5.375rem;
        background-color: $primary;
    }
    &--red {
        &::after {
            background-color: $red;
        }
    }
    &--yellow {
        &::after {
            background-color: $yellow;
        }
    }
    &--blue {
        &::after {
            background-color: $blue;
        }
    }
}
