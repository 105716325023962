@use "node_modules/utopia-core-scss/src/utopia" as utopia;

/* @link https://utopia.fyi/space/calculator?c=320,16,1.125,1240,18,1.125,7,3,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
    @include utopia.generateSpaceScale(
        (
            "minWidth": 320,
            "maxWidth": 1240,
            "minSize": 16,
            "maxSize": 18,
            "positiveSteps": (
                1.5,
                2,
                3,
                4,
                6
            ),
            "negativeSteps": (
                0.75,
                0.5,
                0.25
            ),
            "customSizes": (
                "s-l"
            ),
            "prefix": "space-",
            "relativeTo": "viewport-width"
        )
    );
}
