@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src:
    url(../../fonts/Poppins-normal-300.woff2) format("woff2"),
    url(../../fonts/Poppins-normal-300.woff) format("woff"),
    url(../../fonts/Poppins-normal-300.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Poppins);
  src:
    local("Poppins"),
    url(../../fonts/Poppins?#iefix) format("embedded-opentype"),
    url(../../fonts/Poppins.woff2) format("woff2"),
    url(../../fonts/Poppins.woff) format("woff"),
    url(../../fonts/Poppins.ttf) format("truetype"),
    url(../../fonts/Poppins.svg#Poppins) format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src:
    url(../../fonts/Poppins-normal-500.woff2) format("woff2"),
    url(../../fonts/Poppins-normal-500.woff) format("woff"),
    url(../../fonts/Poppins-normal-500.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src:
    url(../../fonts/Poppins-normal-600.woff2) format("woff2"),
    url(../../fonts/Poppins-normal-600.woff) format("woff"),
    url(../../fonts/Poppins-normal-600.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src:
    url(../../fonts/Poppins-normal-700.woff2) format("woff2"),
    url(../../fonts/Poppins-normal-700.woff) format("woff"),
    url(../../fonts/Poppins-normal-700.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src:
    url(../../fonts/Poppins-italic-400.woff2) format("woff2"),
    url(../../fonts/Poppins-italic-400.woff) format("woff"),
    url(../../fonts/Poppins-italic-400.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src:
    url(../../fonts/Poppins-italic-500.woff2) format("woff2"),
    url(../../fonts/Poppins-italic-500.woff) format("woff"),
    url(../../fonts/Poppins-italic-500.ttf) format("truetype");
  font-display: swap;
}
