.ls-0 {
    letter-spacing: 0;
}
.ls-1 {
    letter-spacing: -0.23px;
}
.ls-2 {
    letter-spacing: -0.35px;
}
.ls-3 {
    letter-spacing: -0.4px;
}
.ls-4 {
    letter-spacing: -0.5px;
}
.ls-5 {
    letter-spacing: -0.7px;
}
.ls-6 {
    letter-spacing: -1.12px;
}
.ls-7 {
    letter-spacing: -1.41px;
}
