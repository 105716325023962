.main-header {
    @include widescreen {
        background-image: url("img/taca1.png");
        background-repeat: no-repeat;
        background-position: 100% 0;
    }
    &__top {
        p,
        time {
            letter-spacing: 3.5px;
            @include tablet {
                span {
                    display: block;
                }
            }
        }
        time + p {
            border-bottom: 1px solid $secondary;
            width: max-content;
            margin-left: auto;
            @include mobile {
                margin-right: auto;
            }
        }
    }
}
