.pop-up {
    --focus-color: #{$primary};
    max-width: 900px;
    @include tablet {
        background-image: url("img/imatge-fons-popup.png");
        background-repeat: no-repeat;
        background-position: 70% 100%;
    }
}
.mfp-close {
    margin-top: 0 !important;
    top: 1rem;
    right: 1rem;
    opacity: 1;
}
