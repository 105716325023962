.programa {
    background-image: url("img/linies.svg");
    &__list {
        list-style-type: ""; /*https://www.matuzo.at/blog/2023/removing-list-styles-without-affecting-semantics*/
        > li:nth-child(even) {
            background-color: rgba($primary, 0.2);
        }
        time {
            line-height: 1.1;
        }
    }
    &__container {
        border-left: 1px solid $primary;
    }
}
