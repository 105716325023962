// mòbil
.hamburger {
    cursor: default;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    // @include tablet {
    //     top: var(--space-2xs);
    // }
    @include desktop {
        display: none;
    }
}
.navigation {
    &::after {
        content: "";
        display: block;
        width: 6.25rem;
        height: 1px;
        background: $primary;
        margin-top: var(--space-l);
    }
}
body:has(.main-content > .slider-container) .navigation::after {
    display: none;
}
#menu {
    @include touch {
        background: #fff;
        position: absolute;
        left: 0;
        right: 0;
        // outline: 1px solid red;
        // top: var(--fluid-59-86);
        top: 60px;
        max-width: 80.875rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: var(--space-l);
        padding-right: var(--space-l);
        padding-bottom: var(--space-l);
        z-index: 10;
    }
    @include desktop {
        display: block;
        margin-top: var(--space-l);
    }
}

// arregla visualització menu al carregar la pàgina
@include touch {
    .js #menu {
        display: none;
    }
}
.hamburger[aria-expanded="true"] + #menu {
    display: initial;
}
