/* Override Bulma icon margin styles */
// .button .icon:first-child {
//     margin: 0 0.5em 0 0 !important;
// }

.button {
    // height: auto;
    // color: $button-color;
    font-weight: 600;
    text-decoration: none;
    border-radius: 4px;
    // gap: var(--space-3xs);
    // font-size: var(--step--1);
    // redefinim el color de l'outline, ja que per defecte és 'currentColor' i no es veu quan el color de text és blanc.
    // &:focus,
    // &:focus-visible {
    //     outline-color: $black !important;
    // }
    // white-space: normal;
    &:hover {
        // color: $button-background-color;
        background-color: transparent;
    }
    &--transp {
        background-color: transparent;
        border-color: $primary;
        &:hover {
            color: $primary;
            border-color: $primary;
            text-decoration: underline;
        }
    }
}
// p.button {
//     // &:hover,
//     // &:focus {
//     //     background-color: #fff;
//     //     a {
//     //         color: $button-background-color;
//     //     }
//     // }
//     // a {
//     //     // color: $button-color;
//     //     text-decoration: none;
//     // }
// }
