.scrolltop {
    // .js & {
    //     opacity: 0;
    // }
    // width: 50px;
    // box-sizing: content-box;
    position: fixed;
    bottom: var(--space-l);
    right: var(--space-l);
    // transition: opacity 0.3s ease-in-out;
    // z-index: 2;
    // position: sticky;
    // top: 20px;
    // &.visible {
    //     opacity: 1;
    // }
    // &:hover {
    //     filter: invert(1);
    // }
}
